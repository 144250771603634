import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import * as Echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment'//导入文件
Vue.prototype.$moment = moment;//赋值使用

import router from '../router'
import store from '../src/store'

Vue.prototype.$echarts = Echarts
Vue.config.productionTip = false

Vue.use(ElementUI);
new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
